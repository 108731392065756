<template>
   <div>
      <big-title>
         Abonar <span>fondos</span>.
      </big-title>
     <!-- PANEL DE CUMPLIMIENTO -->
     <compliance-banner class="mb-4" @loadedRequirements="loadedRequirements"/>
     <div v-if="mainComplianceRequirements <= 0">
      <template v-if="shouldProcessWithStp && isMounted">
        <investor-contract-level-banner/>
        <template v-if="contractLevel == 1 || contractLevel == 2">
          <div class="vx-row">
            <div class="vx-col w-full md:w-2/3 xl:w-3/4">
              <vx-card class="mt-4">
                  <!-- PROCESAMIENTO CON STP -->
                  <stp-account-info :user_id="user_id" :stpClabe="stpClabe" />
                  <!-- FIN PROCESAMIENTO CON STP -->
              </vx-card>
            </div>
            <div class="vx-col w-full md:w-1/3 xl:w-1/4">
              <vx-card class="mt-4 border-primary text-center">
                <div class="vx-row mt-5">
                  <div class="vx-col w-full">
                    <h3>
                      ¡Aumenta tu límite de operación mensual! 
                    </h3>
                  </div>
                </div>
                <div class="vx-row mt-base">
                  <div class="vx-col w-full">
                    <h4 class="regular">
                      Tu límite actual es de <strong>{{currentLimitOperationAmount}}</strong> 
                    </h4>
                  </div>
                </div>
                <div class="vx-row mt-base">
                  <div class="vx-col w-full">
                    <h5 class="mb-5 regular">
                      Para aumentar tu límite {{nextLimitOperationAmount}} solo tienes que
                      <template v-if="contractLevel == 1">
                        ingresar tu domicilio e identificación oficial.
                      </template>
                      <template v-else>
                        realizar un abono desde una cuenta bancaria a tu nombre para validarla.
                      </template>                      
                    </h5>
                  </div>
                </div>
                <div v-if="contractLevel == 1" class="vx-row mt-base">
                  <div class="vx-col w-full">
                    <vs-button
                    id="raise_limit_btn"
                    color="success"
                    type="filled"
                    @click.native="goToIncrementLevel"
                    >Aumentar límite</vs-button>
                  </div>
                </div>
                <div class="vx-row mt-3">
                  <div class="vx-col w-full">
                    <contract-levels-info 
                      :current-level="contractLevel" 
                      :monthly-limit-amount-one="contractLevelData.monthly_limit_1"
                      :monthly-limit-amount-two="contractLevelData.monthly_limit_2"
                      :account-limit-one="contractLevelData.account_limit_1"
                       />
                  </div>
                </div>
              </vx-card>
            </div>
          </div>
        </template>
        <vx-card v-else class="mt-4">
            <!-- PROCESAMIENTO CON STP -->
            <stp-account-info :user_id="user_id" :stpClabe="stpClabe" />
            <!-- FIN PROCESAMIENTO CON STP -->
        </vx-card>
      </template>
      <vx-card v-else-if="isMounted" class="mt-4" title="Ya falta muy poco para que puedas abonar fondos.">
          <div class="vx-row">
            <div class="vx-col w-full">
              <p>
                Pronto podrás realizar ¡tu primera inversión de impacto en RedGirasol! Te pedimos estar atento a tu correo electrónico para recibir 
                la confirmación de que tu cuenta ya está lista para operar. Este proceso puede tomar aproximadamente entre 5 y 10 minutos, y en algunos 
                casos hasta un día hábil. Si tienes alguna duda sobre el proceso de verificación estamos para apoyarte a través del chat de la plataforma 
                o en cualquiera de nuestras redes sociales.
              </p>
            </div>
          </div>
      </vx-card>
     </div>
   </div>
</template>

<script>
import formatHelper from '@mixins/formatHelper';
import currencyHelper from '@mixins/currencyHelper';
import {VMoney} from 'v-money'
import ComplianceBanner from "@components/compliance/ComplianceBanner";
import StpAccountInfo from "@components/stp/StpAccountInfo";
import stpHelper from "@mixins/stpHelper";
import {formatPrice, parseValue} from "@/filters";
import {mapActions, mapState} from "vuex";
import userComplianceHelper from "@/helpers/userComplianceHelper";
import userIdentityHelper from "@/helpers/userIdentityHelper";
import investorInfoHelper from "@/helpers/investorInfoHelper";
import ContractLevelsInfo from "@components/investor/contract-levels/ContractLevelsInfo";
import InvestorContractLevelBanner from "@components/investor/plans/InvestorContractLevelBanner";

export default {
   mixins: [
     formatHelper, 
     currencyHelper, 
     stpHelper, 
    //  cardsHelper, 
     userComplianceHelper, userIdentityHelper, investorInfoHelper],
   directives: {money: VMoney},
  components: {StpAccountInfo,ComplianceBanner,ContractLevelsInfo, InvestorContractLevelBanner},
   data(){
      return {
        isMounted: false,
         aliasCurrency: 0,
         amount: 0,
         money: {
            decimal: '.',
            thousands: ',',
            prefix: '',
            suffix: '',
            precision: 0,
            masked: false
         },

        user_person_type: null,
        user_id: null,
        requirements: null,
        panel: true,

        transfer: '',
        stg: false,
        cards:[],
        cardSelected: '',
        getting: false,
        add:false,
        got_cards: false,
        brand: '',
        name_card: '',
        card_number: '',
        cvv: '',
        month: '',
        year: '',
        card_id: '',
        device: '',
        new_balance: '',
        voucherCompleted: false,
        paymentProcessor: null,
        contractLevelData: null,
        stpClabe: null,
        type: 0,
        fund: '',
        allowed: true,
        mainComplianceRequirements: 0,
      }
   },
  computed: {
    ...mapState("auth", ["roleGeneralData"]),
    isMexicanPM(){
      return this.user_person_type === 0;
    },
    isMexicanPF(){
      return this.user_person_type === 1;
    },
    isForeign(){
      return this.user_person_type === 2 || this.user_person_type === 3;
    },
    isForeignPF(){
      return this.user_person_type === 2;
    },
    isForeignPM(){
      return this.user_person_type === 3;
    },
    isFinancialEntity(){
      return this.user_person_type === 4;
    },
    shouldProcessWithStp(){
      return this.paymentProcessor === 'stp' && this.stpClabe !== null;
    },
    shouldDisplayStpHoldMessage(){
      return this.paymentProcessor === 'stp' && this.stpClabe === null;
    },
    contractLevel(){
      return this.contractLevelData.contract_level;
    },
    currentLimitOperationAmount(){
      if (this.contractLevelData.monthly_operation_limit_amount == -1) {
        return "Ilimitado";
      }else {
        return this.explicitMoneyFormat(this.contractLevelData.monthly_operation_limit_amount);
      }
    },
    nextLimitOperationAmount(){
      if (this.contractLevelData.next_monthly_operation_limit_amount == -1) {
        return "de forma ilimitada";
      }else {
        return "hasta "+this.explicitMoneyFormat(this.contractLevelData.next_monthly_operation_limit_amount);
      }
    }
  },
  async beforeMount(){
    this.showLoading(true)
    this.isMounted = false
    this.user_person_type = parseInt(this.UserPersonType);
    this.user_id = parseInt(this.UserId);
    await this.onUserInfoUpdated();
    await this.getInvestorPaymentProcessor();
    await this.checkForStpInfo();
    await this.getInvestorGeneralData(this.InvestorId);
    await this.getInvestorContractLevelData(this.InvestorId);
    this.isMounted = true
    this.showLoading(false)
  },
  watch: {
    aliasCurrency: function(nval, oldval){
      this.amount = this.unCurrencyFormat(nval);
    },
  },
  methods: {
    ...mapActions('auth', ['getInvestorGeneralData']),
    parseValue,
    formatPrice,

    async checkForStpInfo(){
      // solicitar informacion de stp solo si corresponde
      // panel completo y persona fisica mexicana
      if(this.InvestorStatus > 2){
        this.stpClabe = await this.checkOrCreateInvestorAccount(this.InvestorId);
      }
      else {
        if(this.isMexicanPF || this.isMexicanPM){
          this.stpClabe = await this.getInvestorAccountStpClabe(this.InvestorId);
        }
      }
    },
    async getInvestorPaymentProcessor(){
      try {
        let res = await axios.get('/api/inversionista/getPaymentProcessor');
        this.paymentProcessor = res.data.payment_processor;
      } catch (error) {
      }
    },
    async getInvestorContractLevelData(){
      try {
        let res = await axios.get('/api/v2/investor/' + this.InvestorId + '/getContractLevelData');
        this.contractLevelData = res.data;
      } catch (error) {
      }
    },
    loadedRequirements(count) {
      this.mainComplianceRequirements = count;
    },
    async goToIncrementLevel(){
      await this.$router.replace({name: 'incrementInvestorContractLevel'});
    },
  }
}
</script>

<style lang="css">
  .voucher-box {
    border: 2px solid rgba(var(--vs-rgblack),1);
    border-radius: 6px;
    padding: .68rem 0.75rem;
  }

  .vs-progress--background{
    background-color: #92B9B0 !important;
  }

  .regular {
      font-family: "gilroyregular" !important;
      font-weight: normal;
      line-height: 1.2;
      color: #2c2c2c;
  }

  .vx-card.border-primary{
      border-style: solid;
      border-width: 2px;
  }
</style>

<style lang="scss">
#data-list-card-view-funds {
  .vs-con-table {

    .vs-table--header {
      display: flex;
      flex-wrap: wrap-reverse;
      margin-left: 1.5rem;
      margin-right: 1.5rem;
      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search{
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          &+i {
            left: 1rem;
          }

          &:focus+i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr{
        box-shadow: 0 4px 20px 0 rgba(0,0,0,.05);
        td{
          padding: 10px;
          &:first-child{
            border-top-left-radius: .5rem;
            border-bottom-left-radius: .5rem;
          }
          &:last-child{
            border-top-right-radius: .5rem;
            border-bottom-right-radius: .5rem;
          }
          &.img-container {

            span {
              display: flex;
              justify-content: flex-start;
            }
          }
        }
        td.td-check{
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead{
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text{
          text-transform: uppercase;
          font-weight: 600;
        }
      }
      th.td-check{
        padding: 0 15px !important;
      }
      tr{
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>
